import type {
  HubOrganisation,
  HubOrganisationBasic,
  HubOrganisationFilter,
  HubOrganisationItem
} from '~/types/organisation'

export const useOrganisationStore = defineStore('organisation', () => {
  const currentOrganisation: Ref<HubOrganisation | undefined> = ref()
  const organisationList: Ref<Array<HubOrganisationBasic>> = ref([])
  const detailedOrganisationList: Ref<Array<HubOrganisation>> = ref([])

  const organisation: ComputedRef<HubOrganisation | undefined> = computed(() => {
    return currentOrganisation.value
  })
  const organisations = computed(() => organisationList.value)
  const detailedOrganisations = computed(() => detailedOrganisationList.value)

  async function getOrganisation(
    organisationNodeId: number | string,
    withFilters: boolean = false,
    locale: string = 'en'
  ) {
    let organisation: HubOrganisation | undefined
    if (Array.isArray(detailedOrganisationList.value) && detailedOrganisationList.value.length) {
      organisation = detailedOrganisationList.value.find(x => {
        if (!x || !x.item) {
          return false
        }

        return x.item.organisationNodeId === organisationNodeId
      })

      if (organisation) {
        if (withFilters && !organisation.filters) {
          await fetchOrganisationFilters(organisationNodeId, locale)
          return getOrganisation(organisationNodeId, withFilters, locale)
        }

        currentOrganisation.value = organisation
        return organisation
      }
    }

    organisation = await fetchOrganisation(organisationNodeId, locale)
    if (withFilters) {
      await fetchOrganisationFilters(organisationNodeId, locale)
      return getOrganisation(organisationNodeId, withFilters, locale)
    }

    currentOrganisation.value = organisation
    return organisation
  }

  function updateOrganisation(
    organisationNodeId: number | string,
    updateBody: HubOrganisation | Array<HubOrganisationFilter> | Array<HubOrganisation> | HubOrganisationItem,
    key: 'children' | 'filters' | 'item' | undefined = undefined
  ) {
    // find organisation index
    const organisationIndex: number = detailedOrganisationList.value.findIndex(x => {
      if (!x || !x.item) {
        return false
      }

      return x.item.organisationNodeId === organisationNodeId
    })

    if (organisationIndex === -1) {
      // if index not set, add it to the array, but fail if only trying to update partial
      if (key) {
        throw new Error('Organisation Not Found')
      }

      detailedOrganisationList.value.push(updateBody as HubOrganisation)
    }

    if (key) {
      // @ts-expect-error assume there is the right body for the given key
      detailedOrganisationList.value[organisationIndex][key] = updateBody

      return detailedOrganisationList.value[organisationIndex]
    }

    detailedOrganisationList.value[organisationIndex] = updateBody as HubOrganisation

    return detailedOrganisationList.value[organisationIndex]
  }

  async function fetchOrganisations(forceUpdate: boolean = false) {
    if (
      organisationList.value
      && Array.isArray(organisationList.value)
      && organisationList.value.length
      && !forceUpdate
    ) {
      return organisationList.value
    }

    const { data, error } = await useHubFetch<HubOrganisationBasic[]>('api/v4/organisations/roots', {
      onResponse: ({ response }) => {
        organisationList.value = response._data
      }
    })

    if (!data) {
      throw new Error(error.value?.statusMessage)
    }

    return organisationList.value
  }

  async function fetchOrganisation(organisationNodeId: string | number, cultureInfo: string = 'en'):
  Promise<HubOrganisation> {
    const data = (await $hubFetch(`api/v4/organisations/${organisationNodeId}/hierarchy`, {
      query: {
        cultureInfo
      }
    })) as HubOrganisation

    if (!data) {
      throw new Error('Failed to Fetch Orgnanisation')
    }

    updateOrganisation(organisationNodeId, data)
    return data
  }

  async function fetchOrganisationFilters(organisationNodeId: string | number, cultureInfo: string = 'en'):
  Promise<void> {
    await useHubFetch<Array<HubOrganisationFilter>>(
      `api/v4/organisations/${organisationNodeId}/filters`,
      {
        query: {
          cultureInfo
        },
        onResponse: async ({ response }) => {
          try {
            updateOrganisation(organisationNodeId, response._data, 'filters')
          } catch {
            await fetchOrganisation(organisationNodeId)
            updateOrganisation(organisationNodeId, response._data, 'filters')
          }
        }
      }
    )
  }

  function removeOrganisationFilter(organisationNodeId: number, filterId: string) {
    const organisationIndex: number = detailedOrganisationList.value.findIndex(
      x => x.item.organisationNodeId === organisationNodeId
    )
    if (organisationIndex === -1) {
      return
    }

    const filterIndex = detailedOrganisationList.value[organisationIndex].filters?.findIndex(x => x.id === filterId)
    if (filterIndex === -1 || !filterIndex) {
      return
    }

    detailedOrganisationList.value[organisationIndex].filters?.splice(filterIndex, 1)
  }

  function updateOrganisationFilter(organisationNodeId: number, filterId: string, updateBody: HubOrganisationFilter) {
    const organisationIndex: number = detailedOrganisationList.value.findIndex(
      x => x.item.organisationNodeId === organisationNodeId
    )
    if (
      organisationIndex === -1
      || !detailedOrganisationList.value[organisationIndex].filters
      || !detailedOrganisationList.value[organisationIndex].filters?.length
    ) {
      return
    }

    const filterIndex = detailedOrganisationList.value[organisationIndex].filters?.findIndex(x => x.id === filterId)

    if (filterIndex === -1 || !filterIndex) {
      // add filter if it doesn't exist
      detailedOrganisationList.value[organisationIndex].filters?.push(updateBody)
      return
    }

    detailedOrganisationList.value[organisationIndex].filters[filterIndex] = updateBody
  }

  return {
    currentOrganisation,
    organisationList,
    detailedOrganisationList,

    // publicly usable
    organisation,
    organisations,
    detailedOrganisations,

    getOrganisation,
    fetchOrganisation,
    fetchOrganisations,
    fetchOrganisationFilters,

    updateOrganisation,
    removeOrganisationFilter,
    updateOrganisationFilter
  }
})
